<mat-dialog-content>
    <ul *ngIf="changes">
        <li *ngFor="let e of changes">
            {{e.userName}}, {{e.$.changeDate.displayValue }}
            <div *ngFor="let c of e.changes">
                <strong>{{meta.fields.find(c.key).caption}}</strong>:
                <div style="display: inline-block;">
                    <del class="old-val">{{c.oldDisplayValue}}</del>
                </div>
                <div style="display: inline-block;margin:0 8px">
                    <ins>{{c.newDisplayValue}}</ins>
                </div>

            </div>
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]>
        סגור
    </button>
</mat-dialog-actions>