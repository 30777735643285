<button mat-icon-button [mat-dialog-close]><mat-icon>close</mat-icon></button>

<div mat-dialog-content>
  <ng-container *ngFor="let r of regions">
    <a (click)="copy(r.message)"
      ><h2 style="margin: 0">{{ r.name }}</h2></a
    >
    <ng-container *ngFor="let d of r.districts">
      <a (click)="copy(d.message)"
        ><h3 style="margin: 0" *ngIf="r.districts.length > 1">
          {{ d.name }}
        </h3></a
      >
      <ul style="margin: 0">
        <li *ngFor="let b of d.branches">
          <a (click)="copy(b.message)">{{ b.name }}</a>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</div>
