<div class="data-control3" #myDiv>
  <div style="display: flex; width: 100%" *ngIf="showTextBox()">
    <input
      #theId
      matInput
      [(ngModel)]="_getColumn()!.inputValue"
      type="{{ settings._getColDataType(map) }}"
      (ngModelChange)="settings._colValueChanged(map, record)"
      [errorStateMatcher]="ngErrorStateMatches"
      [ngStyle]="getStyle()"
      [ngModelOptions]="{
        updateOn: settings._getColDataType(map) == 'number' ? 'blur' : 'change'
      }"
    />
    <div
      *ngIf="showDescription()"
      style="
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        width: 100%;
      "
      (click)="click()"
    >
      {{ settings._getColDisplayValue(map, record) }}&nbsp;
    </div>
    <button
      mat-icon-button
      *ngIf="showClick()"
      (click)="click()"
      class="row-button"
    >
      <mat-icon style="height: 19px">{{ getClickIcon() }} </mat-icon>
    </button>
  </div>
  <div
    *ngIf="showReadonlyText() && !showCheckbox()"
    style="overflow: hidden; padding-left: 10px; width: 100%"
  >
    {{ settings._getColDisplayValue(map, record) }}&nbsp;
  </div>
  <div *ngIf="isSelect()" class="my-select" [class.rtl-select]="rightToLeft">
    <select
      matNativeControl
      [(ngModel)]="_getColumn()!.inputValue"
      (ngModelChange)="settings._colValueChanged(map, record)"
    >
      <option *ngFor="let v of getDropDown()" [ngValue]="v.id">
        {{ v.caption }}
      </option>
    </select>
  </div>

  <div *ngIf="showCheckbox()">
    <mat-checkbox
      *ngIf="!showReadonlyText()"
      #theId1
      [(ngModel)]="_getColumn()!.inputValue"
      (ngModelChange)="settings._colValueChanged(map, record)"
      class="fix-label"
    ></mat-checkbox>
    <mat-icon
      style="font-size: 18px; height: 20px"
      *ngIf="showReadonlyText() && _getColumn()!.inputValue"
      >check
    </mat-icon>
  </div>
</div>
<mat-error *ngIf="getError()" [id]="theId" style="font-size: 75%">{{
  getError()
}}</mat-error>
