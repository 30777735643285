<div class="full-page">
  <div #gmap class="grow-item" dir="ltr"></div>

  <div
    [style.max-height]="selectedTasks?.length ? '130px' : '0px'"
    style="
      overflow-x: auto;
      overflow-y: none;
      transition: max-height 0.3s ease-in-out;
    "
  >
    <app-event-card
      [tasks]="selectedTasks"
      [fromMap]="true"
      *ngIf="selectedTasks"
    ></app-event-card>
  </div>
</div>
