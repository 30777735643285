<div class="data-control2">
  <ng-template #theId></ng-template>
  <ng-container *ngIf="!map.customComponent">
    <mat-form-field
      appearance="outline"
      [floatLabel]="getFloatLabel()"
      *ngIf="showTextBox()"
      class="full-width-form-field dense-form-field"
    >
      <mat-label>{{ map.caption }}</mat-label>
      <div style="display: flex">
        <input
          #theId
          matInput
          autocomplete="off"
          [(ngModel)]="_getColumn()!.inputValue"
          type="{{ settings._getColDataType(map) }}"
          (ngModelChange)="settings._colValueChanged(map, record)"
          [errorStateMatcher]="ngErrorStateMatches"
          [ngStyle]="getStyle()"
          [ngModelOptions]="{
            updateOn:
              settings._getColDataType(map) == 'number' ? 'blur' : 'change'
          }"
        />
        <div
          *ngIf="showDescription()"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
            width: 100%;
          "
          (click)="click()"
        >
          {{ settings._getColDisplayValue(map, record) }}&nbsp;
        </div>
      </div>
      <button mat-icon-button matSuffix *ngIf="showClick()" (click)="click()">
        <mat-icon>{{ getClickIcon() }} </mat-icon>
      </button>
      <mat-error *ngIf="getError()" [id]="'theId'">{{ getError() }}</mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      [floatLabel]="getFloatLabel()"
      *ngIf="isSelect()"
      class="full-width-form-field dense-form-field"
    >
      <mat-label>{{ map.caption }}</mat-label>
      <div *ngIf="isSelect()">
        <mat-select
          matNativeControl
          [(ngModel)]="_getColumn()!.inputValue"
          (ngModelChange)="settings._colValueChanged(map, record)"
        >
          <mat-option
            *ngFor="let v of getDropDown()"
            [value]="v.id"
            [class]="map.valueListItemCss?.(v)"
          >
            {{ v.caption }}
          </mat-option>
        </mat-select>
      </div>
      <mat-error *ngIf="getError()" [id]="'theId'">{{ getError() }}</mat-error>
    </mat-form-field>

    <div *ngIf="showCheckbox()">
      <mat-checkbox
        *ngIf="_getEditable()"
        #theId1
        [(ngModel)]="_getColumn()!.inputValue"
        (ngModelChange)="settings._colValueChanged(map, record)"
        class="fix-label"
        >{{ map.caption }}</mat-checkbox
      >
      <div *ngIf="!_getEditable()" style="display: flex; gap: 8px">
        <mat-icon>{{ _getColumn()!.inputValue ? 'check' : 'close' }} </mat-icon
        ><mat-label>{{ map.caption }}</mat-label>
      </div>
      <mat-error *ngIf="getError()" [id]="'theId1'">{{ getError() }}</mat-error>
    </div>
  </ng-container>
</div>
