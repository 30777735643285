<h3 matDialogHeader>{{ args.title }}</h3>

<mat-dialog-content>
  <div>
    <div class="help-text" *ngIf="args.helpText">
      {{ args.helpText }}
    </div>
    <data-area [settings]="area"> </data-area>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    *ngFor="let b of args.buttons"
    (click)="buttonClick(b, $event)"
  >
    {{ b.text }}
  </button>
  <button
    type="button"
    mat-raised-button
    color="warning"
    (click)="dialogRef.close()"
  >
    {{ terms.cancel }}
  </button>

  <button mat-raised-button color="accent" (click)="confirm()">
    {{ terms.ok }}
  </button>
</mat-dialog-actions>
