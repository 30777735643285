<div class="full-page">
  <div *ngIf="!isDialog() && !fromMap">

    <button
      mat-raised-button
      color="primary"
      (click)="addTask(false)"
      *ngIf="!allowDriveTasks() && isDispatcher()"
    >
      הוספת נסיעה
    </button>
    <button
      mat-raised-button
      color="primary"
      style="background-color: green; margin: 5px;"
      (click)="addTask(true)"
      *ngIf="showDriveOfferButton()"
    >
      פרסם הצעת נסיעה
    </button>

    <button
      mat-raised-button
      color="primary"
      style="background-color: #3f51b5; margin: 5px;"
      (click)="navigate('request')"
      *ngIf="showPickupRequestButton()"
    >
      פרסם בקשת טרמפ
    </button>
    <app-dots-menu [buttons]="buttons" *ngIf="isDispatcher()"></app-dots-menu>
    <data-area [settings]="area"></data-area>
    <div
      *ngIf="tasks.length"
      class="one-line-of-combos"
      style="
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 4px;
      "
    >
      <div style="align-self: center">
        <button
          mat-raised-button
          color="primary"
          (click)="sortByDistance()"
          *ngIf="!showSortOptions"
        >
          <mat-icon>near_me</mat-icon>קרוב אלי
        </button>
      </div>

      <mat-form-field
        *ngIf="showSortOptions"
        appearance="outline"
        class="dense-form-field"
        style="margin-bottom: -8px; flex-basis: 1px; flex-grow: 1"
      >
        <mat-label>הצג לפי</mat-label>
        <div>
          <select
            matNativeControl
            [(ngModel)]="currentSort"
            (change)="currentSort.selected()"
          >
            <option *ngFor="let v of sortOptions" [ngValue]="v">
              {{ v.caption }}
            </option>
          </select>
        </div>
      </mat-form-field>
      <mat-form-field
        *ngIf="dates.length >= 2"
        appearance="outline"
        class="dense-form-field"
        style="margin-bottom: -8px; flex-basis: 1px; flex-grow: 1"
      >
        <mat-label>מתי</mat-label>
        <select
          matNativeControl
          [(ngModel)]="filterDate"
          (change)="refreshFilters(true)"
        >
          <option *ngFor="let v of dates" [ngValue]="v.id">
            {{ v.caption }}
          </option>
        </select>
      </mat-form-field>
      <div style="align-self: center">
        <button
          *ngIf="!showMap"
          mat-raised-button
          (click)="toggleShowMap(true)"
          color="primary"
        >
          <mat-icon>map</mat-icon> מפה
        </button>
        <button
          *ngIf="showMap"
          mat-raised-button
          color="primary"
          (click)="toggleShowMap(false)"
        >
          חזרה לרשימה
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="isDialog()">
    {{ title }}
  </div>
  <div *ngIf="showMap" class="grow-item" style="position: relative">
    <app-noam-test
      [tasks]="tasksForMap"
      [startLocation]="startLocation"
      [endLocation]="endLocation"
    ></app-noam-test>
  </div>
  <div *ngIf="!showMap" [class.grow-item]="!fromMap">
    <div *ngFor="let d of urgencies">
      <ng-container *ngIf="hasEvents(d)">
        <strong *ngIf="urgencies.length > 1">{{ d.urgency }}:</strong>
        <div class="events-container" [transition-group]="'flip-list'">
          <ng-container *ngFor="let e of d.events; trackBy: trackBy">
            <mat-card
              transition-group-item
              class="event-card {{e.isDrive ? 'drive-event-card' : ''}}"
              [class.registered]="isRegisteredToEvent(e) && showingAllTasks"
              *ngIf="filter(e)"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 5px;
                "
              >
                <strong (click)="eventDetails(e)">
                  {{ e.title }}
                </strong>
                <a (click)="eventDetails(e)" *ngIf="!isFull(e) && fromMap">{{
                  'לפרטים'
                }}</a>
                <app-dots-menu
                  [item]="e"
                  [buttons]="menuOptions"
                  *ngIf="(e.isDispatcher() || eventBelongsToMe(e)) && !fromMap"
                ></app-dots-menu>
              </div>
              <div class="event-info" (click)="eventDetails(e)">
                <app-trip-subtitle
                  style="width: 100%"
                  [onTheWayBack]="onTheWayBack(e)"
                  [distance]="distance(e)"
                  [e]="e"
                >
                  <a *ngIf="(!isFull(e) || e.isDrive) && !fromMap">{{ 'לפרטים' }}</a>
                  <strong *ngIf="isFull && !e.isDrive">
                    {{ e.taskStatus.caption }}
                    <span *ngIf="e.driver">, {{ e.driver.name }}</span>
                  </strong>
                </app-trip-subtitle>
              </div>
            </mat-card>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div style="text-align: center; padding: 16px; padding-bottom: 48px">
      <a routerLink="/volunteer"
        >רוצים עוד נסיעות? לחצו כאן להרשמה לעוד חמלים</a
      >
    </div>
  </div>
</div>
