<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  "
>
  <mat-card style="min-width: 200px; max-width: 400px">
    <ng-content></ng-content>
  </mat-card>
</div>
