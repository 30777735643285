<div
  mat-dialog-title
  style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  "
>
  <div style="text-wrap: nowrap">
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="args.showFailStatus">מה לא הסתדר?</div>
</div>

<mat-dialog-content>
  <div *ngIf="args.showFailStatus">
    <mat-radio-group
      [(ngModel)]="whatWentWrong"
      style="display: flex; flex-direction: column"
    >
      <mat-radio-button [value]="0"
        >לא אוכל לבצע, שייכו לנהג אחר
      </mat-radio-button>
      <mat-radio-button [value]="21" *ngIf="showNotRelevant()"
        >הנסיעה כבר לא רלוונטית
      </mat-radio-button>
      <mat-radio-button [value]="23">לא עונים </mat-radio-button>
      <mat-radio-button [value]="22">בעיה אחרת </mat-radio-button>
    </mat-radio-group>
  </div>
  <data-control [field]="$.notes"></data-control>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between">
  <button mat-raised-button color="primary" (click)="save()">שמור</button>
</mat-dialog-actions>
