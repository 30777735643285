<div>
  <h3 matDialogHeader>{{ args.message }}</h3>

  <mat-dialog-actions>
    <button
      mat-raised-button
      color="warning"
      (click)="close()"
      *ngIf="args.isAQuestion"
    >
      {{ terms.no }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="close()"
      *ngIf="!args.isAQuestion"
    >
      {{ terms.ok }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="select()"
      *ngIf="args.isAQuestion"
    >
      {{ terms.yes }}
    </button>
  </mat-dialog-actions>
</div>
