<div style="display: flex" mat-dialog-title>
  <h2 style="margin-bottom: 0">שגיאה בשליפת מיקום נוכחי</h2>
  <button mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  יש לאפשר שרותי מיקום לדפדפן בטלפון
  <br />
  באייפון יש לשנות את ההגדרה הזו מ"אף פעם" <strong>ל"שאל"</strong>
  <br />
  <img src="assets/location-help.png" style="width: 100%" />
</div>
