<div
  mat-dialog-title
  style="display: flex; justify-content: space-between; align-items: center"
>
  <h2 style="margin-bottom: 0">{{ e.title }}</h2>
  <div style="text-wrap: nowrap">
    <app-dots-menu
      [item]="e"
      [buttons]="menuOptions"
      *ngIf="isDispatcher()"
    ></app-dots-menu>

    <button mat-icon-button *ngIf="!forExternalViewer" [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content
  class="dialog-container"
  [style.padding-bottom]="'30px'"
  [style.min-width]="'320px'"
  [style.max-height]="forExternalViewer ? 'inherit' : undefined"
>
  <app-trip-subtitle [e]="e">
    <button mat-raised-button (click)="showMap = !showMap" color="primary">
      <mat-icon>map</mat-icon> מפה
    </button>
  </app-trip-subtitle>
  <ng-content></ng-content>
  <app-noam-test
    [tasks]="[e]"
    *ngIf="showMap"
    [singleTrip]="true"
  ></app-noam-test>
  <hr />
  <div *ngIf="e.imageId" style="width: 200px; height: 200px">
    <img
      *ngIf="e.imageId"
      [src]="getImageUrl()"
      style="
        width: 100%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit: contain;
      "
    />
  </div>
  <strong *ngIf="showContactInfoMessage()" style="text-align: center">
    פרטי הקשר יוצגו לאחר שתלחץ על הכפתור "{{ driverAssignButtonText() }}" למטה.
    תוכל לחזור בך על ידי לחיצה על הכפתור "נתקלתי בבעיה"
  </strong>
  <app-phone-details
    *ngIf="useFillerInfo()"
    [args]="{
      phone: e.requesterPhone1,
      name: e.requesterPhone1Description,
      title: 'ממלא הבקשה',
      messageContext: e.getShortDescription()
    }"
  >
  </app-phone-details>
  <div *ngIf="e.address" class="space-above">
    <strong>כתובת מוצא</strong>
    <div class="phone-container" style="justify-content: space-between">
      <span (click)="openSourceWaze()">
        <img src="assets/waze.png" />
      </span>
      <div style="flex-grow: 1">
        <a (click)="openSourceWaze()">
          <span style="display: block"> {{ e.address }} </span>
        </a>
      </div>
      <span (click)="openSourceGoogleMap()">
        <img src="assets/google_maps_icon.png" />
      </span>
    </div>

    <app-phone-details
      [args]="{
        phone: e.phone1,
        name: e.phone1Description,
        title: 'איש קשר לאיסוף',
        messageContext: e.getShortDescription()
      }"
    >
    </app-phone-details>
    <app-phone-details
      [args]="{
        phone: e.phone2,
        name: e.phone2Description,
        title: 'איש קשר לאיסוף 2',
        messageContext: e.getShortDescription()
      }"
    >
    </app-phone-details>
  </div>
  <div
    class="space-above"
    style="white-space: pre-line"
    *ngIf="e.description || e.privateDriverNotes"
  >
    <strong>פרטים נוספים: </strong>
    {{ e.description }}
    {{ e.privateDriverNotes }}
  </div>
  <div
    style="display: flex; place-content: space-between; margin-top: 8px"
    *ngIf="showThumbsUpOnPickup()"
  >
    <strong>👍👍👍 </strong>
    <button
      mat-raised-button
      color="error"
      (click)="cancelPickedUp()"
      *ngIf="showCancelPickedUp()"
    >
      נלחץ בטעות
    </button>
  </div>
  <div>
    <button
      mat-raised-button
      style="margin-top: 16px; background-color: green; color: white"
      *ngIf="showPickedUp()"
      (click)="pickedUp()"
    >
      אספתי בהצלחה
    </button>
  </div>
  <hr />
  <div *ngIf="e.toAddress" class="space-above">
    <strong>כתובת יעד</strong>
    <div class="phone-container" style="justify-content: space-between">
      <span (click)="openTargetWaze()">
        <img src="assets/waze.png" />
      </span>
      <div style="flex-grow: 1">
        <a (click)="openTargetWaze()">
          <span style="display: block"> {{ e.toAddress }} </span>
        </a>
      </div>
      <span (click)="openTargetGoogleMap()">
        <img src="assets/google_maps_icon.png" />
      </span>
    </div>
    <app-phone-details
      [args]="{
        phone: e.toPhone1,
        name: e.tpPhone1Description,
        title: 'איש קשר למסירה',
        messageContext: e.getShortDescription()
      }"
    >
    </app-phone-details>
    <app-phone-details
      [args]="{
        phone: e.toPhone2,
        name: e.tpPhone2Description,
        title: 'איש קשר למסירה 2',
        messageContext: e.getShortDescription()
      }"
    >
    </app-phone-details>
    <div *ngIf="driver || e.isDrive">
      <hr />
      <app-phone-details
        *ngIf="!isDrive() && driver"
        [args]="{
          phone: driver.phone,
          name: driver.name,
          title: 'נהג',
          messageContext: e.getShortDescription() + '\n' + e.getLink() + '\n'
        }"
      ></app-phone-details>
      <app-phone-details
        *ngIf="isDrive()"
        [args]="{
          phone: e.driverPhonePublic,
          name: e.driverNamePublic,
          title: 'נהג',
          messageContext: e.getShortDescription() + '\n' + e.getLink() + '\n'
        }"
      ></app-phone-details>
    </div>
  </div>
</mat-dialog-content>
<mat-slide-toggle
  *ngIf="isDrive() && canModify()"
  [matTooltip]="'הסתר נסיעה מהציבור'"
  (toggleChange)="setIsFull()"
  [checked]="isFull()"
  style="margin-bottom: 16px"
>
  הנסיעה מלאה
</mat-slide-toggle>
<mat-dialog-actions
  *ngIf="!forExternalViewer"
  style="
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
  "
>

  <button
    [disabled]="inProgress"
    mat-raised-button
    color="primary"
    *ngIf="showAssign()"
    (click)="registerToEvent()"
  >
    {{ driverAssignButtonText() }}
  </button>
  <button
    mat-raised-button
    style="background-color: green; color: white"
    *ngIf="canModify() && isAssigned()"
    (click)="completed()"
  >
    השלמתי בהצלחה
  </button>
  <button
    mat-raised-button
    color="error"
    *ngIf="showWillNotDo()"
    (click)="problem()"
  >
    {{e.isDrive ? 'בטל נסיעה' : 'לא אבצע'}}
  </button>
  <ng-container *ngIf="showCancel()">
    <strong>{{ e.taskStatus.caption }} </strong>
    <button mat-raised-button color="error" (click)="clickedByMistake()">
      נלחץ בטעות
    </button>
  </ng-container>
</mat-dialog-actions>
