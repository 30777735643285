<ng-container *ngFor="let o of stats">
  <h4>{{ o.name }}</h4>
  <apx-chart
    [series]="o.chart.series"
    [chart]="o.chart.chart"
    [colors]="o.chart.colors"
    [yaxis]="o.chart.yaxis"
    [dataLabels]="commonOptions.dataLabels"
    [markers]="commonOptions.markers"
    [stroke]="commonOptions.stroke"
    [grid]="commonOptions.grid"
    [xaxis]="commonOptions.xaxis"
    [tooltip]="commonOptions.tooltip"
  ></apx-chart>
</ng-container>

<strong>עשרת הנהגים המובילים</strong>
<data-area [settings]="area"></data-area>
<table class="table">
  <thead>
    <tr>
      <th>נהג</th>
      <th>{{ showKm ? 'ק"מ' : 'נסיעות' }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let d of topDrivers; let i = index">
      <td style="text-align: right">{{ i + 1 }}. {{ d.name }}</td>
      <td>{{ d.sum }}</td>
    </tr>
  </tbody>
</table>
