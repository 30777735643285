<div *ngIf="!remult.authenticated() && !doesNotRequireLogin()">
  <app-card-in-middle>
    <div style="display: flex; flex-direction: column">
      <div style="align-self: center">
        <img [src]="getLogo()" height="150px" />
      </div>
      <markdown [data]="introText"> </markdown>

      <mat-form-field
        *ngIf="signIn.askForOtp"
        appearance="outline"
        class="full-width-form-field dense-form-field"
      >
        <mat-label>{{ signIn.$.otp.metadata.caption }}</mat-label>
        <div style="display: flex">
          <input
            #theId
            matInput
            cdkFocusInitial
            type="tel"
            autocomplete="one-time-code"
            inputmode="numeric"
            maxlength="6"
            pattern="\d{6}"
            [(ngModel)]="signIn.$.otp.inputValue"
          />
        </div>
      </mat-form-field>
      <data-area [settings]="area"> </data-area>
      <button mat-raised-button color="primary" (click)="doSignIn()">
        {{signIn.isSignup ? 'הרשמה' : 'כניסה'}}
      </button>
    </div>
  </app-card-in-middle>
</div>

<div
  style="height: 100%; display: flex; flex-direction: column"
  *ngIf="remult.authenticated() || doesNotRequireLogin()"
>
  <mat-toolbar color="primary" style="flex-shrink: 0">
    <div>
      <div
        [matBadge]="updates.waitingUpdates"
        [matBadgeHidden]="!updates.waitingUpdates"
        matBadgeOverlap="false"
        matBadgePosition="below before"
        matBadgeColor="accent"
        class="alert-badge"
      ></div>
      <div
        [matBadge]="drafts + relevanceCheck + problems"
        [matBadgeHidden]="!drafts && !relevanceCheck && !problems"
        matBadgeColor="warn"
        matBadgePosition="below before"
        class="draft-badge"
      >
        <button mat-button class="sidenav-toggle" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>
    <span class="toolbar-title">{{ currentTitle() }}</span>
    <ng-container *ngIf="remult.authenticated()">
      <button mat-flat-button color="primary" [matMenuTriggerFor]="menu">
        {{ remult.user!.name }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="updateInfo()">
          {{ terms.updateInfo }}
        </button>

        <button mat-menu-item (click)="signOut()">{{ terms.signOut }}</button>
      </mat-menu>
    </ng-container>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav
      #sidenav
      class="app-sidenav mat-elevation-z10 no-print"
      [opened]="!uiService.isScreenSmall()"
      [mode]="uiService.isScreenSmall() ? 'over' : 'side'"
    >
      <div style="width: 100%; text-align: center"></div>
      <h4>{{ title }}</h4>
      <h4 *ngIf="remult.authenticated()">
        {{ terms.hello }} {{ remult.user!.name }}
      </h4>
      <mat-nav-list role="list">
        <mat-list-item routerLinkActive="active-route" *ngIf="showTrips()">
          <a
            matLine
            [routerLink]="['/']"
            (click)="routeClicked()"
            class="alert-line-badge"
            >נסיעות</a
          >
        </mat-list-item>
        <mat-list-item routerLinkActive="active-route" *ngIf="isDispatcher()">
          <a
            matLine
            [matBadge]="problems"
            [matBadgeHidden]="!problems"
            [routerLink]="['/בעיות']"
            (click)="routeClicked()"
            class="draft-line-badge"
            matBadgeColor="warn"
            matBadgePosition="above after"
            >בעיות</a
          >
        </mat-list-item>
        <mat-list-item routerLinkActive="active-route" *ngIf="isDispatcher()">
          <a
            matLine
            [routerLink]="['/עדכונים']"
            (click)="routeClicked()"
            class="alert-line-badge"
            [matBadge]="updates.waitingUpdates"
            [matBadgeHidden]="!updates.waitingUpdates"
            matBadgeColor="warn"
            matBadgePosition="above after"
            >עדכונים</a
          >
        </mat-list-item>
        <mat-list-item routerLinkActive="active-route">
          <a
            matLine
            [href]="['https://sites.google.com/view/trempim']"
            class="alert-line-badge"
            >מידע וסירטוני הסבר</a
          >
        </mat-list-item>
        <mat-list-item routerLinkActive="active-route" *ngIf="showDriverTrips()">
          <a
            matLine
            [routerLink]="['/search']"
            (click)="routeClicked()"
            class="alert-line-badge"
            >חיפוש נסיעה</a
          >
        </mat-list-item>
        <ng-container *ngFor="let m of router.config">
          <mat-list-item
            *ngIf="shouldDisplayRoute(m)"
            routerLinkActive="active-route"
          >
            <a matLine [routerLink]="m.path" (click)="routeClicked()">{{
              routeName(m)
            }}</a>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <div class="app-sidenav-content">
      <div class="wrapper" style="height: 100%">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-container>
</div>
