<div style="height: 100%; display: flex; flex-direction: column">
  <Data-Filter [settings]="settings" #dataFilter style="flex-shrink: 0">
  </Data-Filter>
  <div
    class="dataGrid"
    #dataGridDiv
    *ngIf="settings && settings.columns"
    [style.height]="_getHeight()"
  >
    <div class="inner-wrapper">
      <div class="dataGridHeaderArea">
        <div class="dataGridRow">
          <div class="data-grid-row-more-buttons dataGridDataCell">
            <button
              mat-icon-button
              [matMenuTriggerFor]="updatedRowOptions"
              *ngIf="hasVisibleGridButtons()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <div
            class="data-grid-row-selection dataGridDataCell"
            *ngIf="settings.settings?.allowSelection"
          >
            <mat-checkbox
              [indeterminate]="settings.selectAllIntermitent()"
              [checked]="settings.selectAllChecked()"
              [matTooltip]="
                !settings.selectAllChecked()
                  ? settings.selectedRows.length.toString()
                  : settings.totalRows
                  ? settings.totalRows.toString()
                  : rightToLeft
                  ? 'רבים'
                  : 'many'
              "
              (change)="settings.selectAllChanged($event)"
            >
            </mat-checkbox>
          </div>
          <mat-menu #updatedRowOptions="matMenu">
            <ng-container *ngFor="let b of gridButtons">
              <button mat-menu-item *ngIf="b.visible!()" (click)="b.click!()">
                <mat-icon *ngIf="b.icon">{{ b.icon }}</mat-icon
                >{{ b.textInMenu ? b.textInMenu() : b.name }}
              </button>
            </ng-container>
          </mat-menu>
          <div
            class="dataGridHeaderCell headerWithFilter"
            *ngFor="
              let map of settings.columns.getGridColumns(
                this.plugin.dataControlAugmenter
              )
            "
            [style.flex]="getColFlex(map)"
            [style.width]="getColWidth(map)"
            draggable="true"
            (dragstart)="dragStart(map)"
            (dragover)="dragOver(map, $event)"
            (drop)="onDrop(map)"
          >
            <span (click)="settings.sort(map.field)">{{ map.caption }}</span>

            <span
              class="filterButton"
              *ngIf="map.field"
              [class.filteredFilterButton]="isFiltered(map.field)"
              (click)="showFilterColumnDialog(map)"
            >
              <mat-icon>filter_alt</mat-icon>
            </span>

            <mat-icon *ngIf="settings.sortedAscending(map.field)"
              >arrow_downward</mat-icon
            >
            <mat-icon
              class="glyphicon glyphicon-chevron-down pull-right"
              *ngIf="settings.sortedDescending(map.field)"
            >
              arrow_upward</mat-icon
            >
          </div>
          <div
            class="dataGridButtonHeaderCell"
            *ngIf="rowButtons && rowButtons.length > 0 && displayButtons"
            [class.col-xs-1]="rowButtons && rowButtons.length < 3"
          >
            <button
              mat-icon-button
              *ngIf="showSaveAllButton()"
              (click)="saveAllClick()"
              style="line-height: 36px; max-height: 28px"
            >
              <mat-icon
                [matTooltip]="saveAllText()"
                class="row-button"
                style="font-size: 24px"
                >done_all</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
      <div class="dataGridBodyArea">
        <div
          class="dataGridRow"
          *ngFor="let record of settings.restList"
          [className]="_getRowClass(record)"
          (click)="rowClicked(record)"
        >
          <div class="data-grid-row-more-buttons dataGridDataCell">
            <button
              mat-icon-button
              [matMenuTriggerFor]="updatedRowOptions"
              *ngIf="hasVisibleButton(record)"
            >
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
          <div
            class="data-grid-row-selection dataGridDataCell"
            *ngIf="settings.settings?.allowSelection"
          >
            <mat-checkbox
              [checked]="settings.isSelected(record)"
              (change)="settings.selectedChanged(record)"
              (click)="clickOnselectCheckboxFor(record, $event)"
            >
            </mat-checkbox>
          </div>
          <mat-menu #updatedRowOptions="matMenu">
            <ng-container *ngFor="let b of rowButtons">
              <button
                mat-menu-item
                *ngIf="b.visible!(record)"
                (click)="clickRowButton(b, record)"
              >
                <mat-icon *ngIf="b.icon">{{ b.icon }}</mat-icon
                >{{ getButtonText(b, record) }}
              </button>
            </ng-container>
          </mat-menu>
          <div
            class="dataGridDataCell"
            *ngFor="
              let map of settings.columns.getGridColumns(
                this.plugin.dataControlAugmenter
              )
            "
            [className]="_getRowColumnClass(map, record)"
            [style.flex]="getColFlex(map)"
            [style.width]="getColWidth(map)"
          >
            <data-control3
              [settings]="settings.columns"
              [map]="map"
              [record]="record"
              [rightToLeft]="rightToLeft"
            >
            </data-control3>
          </div>
          <div
            class="dataGridButtonCell"
            *ngIf="rowButtons.length > 0 && displayButtons"
            style="white-space: nowrap"
          >
            <ng-container *ngFor="let b of rowButtons">
              <button
                mat-icon-button
                *ngIf="b.visible!(record) && b.icon && b.showInLine"
                (click)="b.click!(record)"
                matTooltip="{{ getButtonText(b, record) }}"
                class="row-button"
              >
                <mat-icon *ngIf="b.icon">{{ b.icon }} </mat-icon> {{ b.name }}
              </button>
              <button
                mat-raised-button
                *ngIf="b.visible!(record) && !b.icon && b.showInLine"
                (click)="b.click!(record)"
                class="row-button"
              >
                {{ b.name }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="dataGridFooterArea"
    *ngIf="settings.restList && settings.settings?.showPagination"
  >
    <div class="dataGridFooterButtonGroup">
      <button mat-icon-button (click)="settings.userChooseColumns()">
        <mat-icon>settings</mat-icon>
      </button>

      <span class="hide-on-narrow"
        >{{ rightToLeft ? 'שורות בדף' : 'lines per page' }}
        <select
          class="form-control"
          style="width: 80px; display: inline-block"
          [(ngModel)]="settings.rowsPerPage"
          (change)="settings.reloadData()"
        >
          <option *ngFor="let r of settings.rowsPerPageOptions" value="{{ r }}">
            {{ r }}
          </option>
        </select>
      </span>
    </div>
    <div class="dataGridFooterButtonGroup">
      <button mat-icon-button (click)="settings.firstPage()">
        <mat-icon>{{ rightToLeft ? 'last_page' : 'first_page' }} </mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="settings.page == 1"
        (click)="settings.previousPage()"
      >
        <mat-icon>{{
          rightToLeft ? 'chevron_right' : 'chevron_left'
        }}</mat-icon>
      </button>
      {{ getBottomLine() }}

      <button
        mat-icon-button
        [disabled]="
          !(settings.restList.items && settings.restList.items.length > 0)
        "
        (click)="settings.nextPage()"
      >
        <mat-icon>{{
          !rightToLeft ? 'chevron_right' : 'chevron_left'
        }}</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="settings.allowUpdate && settings.allowInsert"
        (click)="settings.addNewRow()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="showSaveAllButton()"
        (click)="saveAllClick()"
        style="line-height: 36px; max-height: 28px"
      >
        <mat-icon
          [matTooltip]="saveAllText()"
          class="row-button"
          style="font-size: 24px"
          >done_all</mat-icon
        >
      </button>
    </div>
    <div *ngIf="settings.showSelectColumn" class="selectColumnsArea">
      Select Columns
      <ol>
        <ng-container *ngFor="let c of settings.currList; let i = index">
          <li *ngIf="i < settings.columns.numOfColumnsInGrid">
            <select
              [(ngModel)]="settings.currList[i]"
              class="form-control selectColumnCombo"
              style="max-width: 100px"
              (change)="settings.columns.colListChanged()"
            >
              <option *ngFor="let o of settings.origList" [ngValue]="o">
                {{ o.caption }}
              </option>
            </select>
            <input [(ngModel)]="c.width" style="width: 50px; display: inline" />
            <button
              mat-icon-button
              *ngIf="settings.currList.length > 1"
              (click)="settings.deleteCol(c)"
            >
              <mat-icon>cancel</mat-icon>
            </button>
            <button mat-icon-button (click)="addCol(c)">
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="i < settings.currList.length - 1"
              (click)="settings.columns.moveCol(c, 1)"
            >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="i > 0"
              (click)="settings.columns.moveCol(c, -1)"
            >
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
          </li>
        </ng-container>
      </ol>
      <button
        (click)="settings.resetColumns()"
        class="btn glyphicon glyphicon-repeat"
      ></button>
    </div>
  </div>

  <data-area
    *ngIf="settings.showDataArea"
    [settings]="getAreaSettings()"
    [columns]="2"
  ></data-area>
</div>
