<div class="full-page">
  <div>
    <mat-tab-group
      #tabGroup
      (selectedTabChange)="onTabChange($event)"
      [selectedIndex]="activeTab"
    >
      <mat-tab label="לטרמפיסטים"></mat-tab>
      <mat-tab label="לנהגים"></mat-tab>
      <mat-tab label="נסיעות שלי"></mat-tab>
      <mat-tab label="חיפוש נסיעה" *ngIf="isDispatcher()"></mat-tab>
      <mat-tab label="נסיעות פעילות" *ngIf="isDispatcher()"> </mat-tab>
    </mat-tab-group>
  </div>

  <div class="grow-item">
    <app-event-card
      [tasks]="events"
      [selectedTab]="getTabs()[activeTab]"
    ></app-event-card>
  </div>
</div>
