<button mat-raised-button color="primary" (click)="addTask()">
  הוספת נסיעה
</button>
<strong> {{ tasks.length }} טיוטות</strong>
<mat-card *ngFor="let t of tasks" style="margin-bottom: 8px">
  <table>
    <tr>
      <td>כותרת:</td>
      <td>{{ t.title }}</td>
    </tr>
<!--    <tr>-->
<!--      <td>קטגוריה:</td>-->
<!--      <td>{{ t.category }}</td>-->
<!--    </tr>-->
    <tr>
      <td>כתובת מוצא:</td>
      <td>{{ t.address }}</td>
    </tr>
    <tr>
      <td>לפי גוגל:</td>
      <td>
        <a
          [href]="getMapsLink(t.addressApiResult)"
          target="_blank"
          [style.color]="errorColor(t.addressApiResult)"
        >
          {{ getGoogleAddress(t.addressApiResult) }}</a
        >
      </td>
    </tr>
    <tr *ngIf="isDispatcher()">
      <td [style.color]="!t.phone1 ? 'red' : ''">טלפון מוצא:</td>
      <td>{{ t.phone1 }}</td>
    </tr>
    <tr>
      <td>כתובת יעד:</td>
      <td>{{ t.toAddress }}</td>
    </tr>
    <tr>
      <td>לפי גוגל:</td>
      <td>
        <a
          [href]="getMapsLink(t.toAddressApiResult)"
          target="_blank"
          [style.color]="errorColor(t.toAddressApiResult)"
        >
          {{ getGoogleAddress(t.toAddressApiResult) }}</a
        >
      </td>
    </tr>
    <tr>
      <td>נוצר ע"י:</td>
      <td>
        <a (click)="showUser(t)"> {{ t.createUser?.name }}</a>
      </td>
    </tr>
    <tr *ngIf="isDispatcher()">
      <td>אחמש אחראי:</td>
      <td>
        <a (click)="showDispatcher(t)"> {{ t.responsibleDispatcher?.name }}</a>
        <button
          mat-button
          color="warn"
          (click)="clearMeAsDispatcher(t)"
          *ngIf="t.responsibleDispatcherId"
        >
          בטל שיוך
        </button>

        <button
          *ngIf="!t.responsibleDispatcherId"
          mat-button
          (click)="assignToMe(t)"
        >
          אני אטפל
        </button>
      </td>
    </tr>
  </table>
  <div>
    <button
      mat-raised-button
      color="primary"
      (click)="show(t)"
      style="margin-left: 16px"
    >
      הצג
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="edit(t)"
      style="margin-left: 16px"
    >
      ערוך
    </button>
    <button
      *ngIf="isDispatcher() && isDraft(t)"
      mat-raised-button
      [color]="'primary'"
      (click)="t.returnToActive()"
    >
      אשר טיוטא
    </button>
    <button
      style="display: inline-block"
      *ngIf="isDispatcher() && isActive(t)"
      mat-raised-button
      [color]="'error'"
      (click)="t.markAsDraft()"
    >
      בטל אישור
    </button>
    <app-dots-menu
      [buttons]="buttons"
      [item]="t"
      *ngIf="isDispatcher()"
    ></app-dots-menu>
  </div>
</mat-card>
