<ng-container *ngIf="args.user">
  <app-phone-details
    [args]="{
      name: args.user.name,
      title: args.title,
      phone: args.user.phone,
      closeDialog
    }"
  ></app-phone-details>
</ng-container>
