<ng-container *ngIf="args.phone">
  <div
    matDialogTitle
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <strong style="font-size: large"> {{ getTitle() }} </strong>
    <button
      mat-icon-button
      (click)="args.closeDialog()"
      *ngIf="args.closeDialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div style="display: flex; align-items: center; gap: 8px; margin-top: 8px">
    <span
      mat-mini-fab
      style="vertical-align: middle; cursor: pointer"
      color="primary"
      (click)="sendWhatsapp()"
    >
      <img src="assets/whatsapp.png" />
    </span>
    <a href="tel:{{ args.phone }}">
      <button mat-mini-fab style="vertical-align: middle" color="primary">
        <mat-icon>call</mat-icon>
      </button>
    </a>
    <div>
      <a href="tel:{{ args.phone }}">
        {{ displayPhone() }}
      </a>
    </div>
  </div>
</ng-container>
