<span
  (click)="showAddAnotherFilterDialog()"
  #theDiv
  class="link"
  *ngIf="settings.columns.filterHelper.filterColumns.length > 0"
  ><span>{{ rightToLeft ? 'סינון' : 'Filter' }}</span></span
>
<span *ngIf="settings.columns.filterHelper.filterColumns.length > 0">: </span>
<span
  *ngFor="let map of settings.columns.filterHelper.filterColumns; let i = index"
>
  <span *ngIf="i > 0">
    <span *ngIf="!rightToLeft">and</span><span *ngIf="rightToLeft">וגם</span>
  </span>
  <span (click)="editFilter(map)" class="link">
    {{ map.caption }} = "{{ this.getCurrentFilterValue(map) }}"</span
  >
</span>
