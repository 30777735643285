<div style="margin: 8px; display: flex; gap: 16px; flex-direction: column">
  <p>
    אפליקציית חגי שינועים פותחה עם תחילת מבצע "חרבות ברזל" בכדי לסייע לכל ארגון
    המבצע שינועים בתקופה מורכבת זו
  </p>

  <div style="display: flex; flex-direction: column">
    <div>להצטרפות חמלים/קהילות צרו קשר:</div>

    <a href="https://wa.me/972507330590" target="_blank">
      <button mat-icon-button>
        <img src="assets/whatsapp.png" style="max-width: 27px" />
      </button>
      שלחו ווטסאפ
    </a>

    <a href="mailto:noam.honig@gmail.com" target="_blank">
      <button mat-icon-button>
        <mat-icon> email </mat-icon>
      </button>
      שלחו מייל noam.honig@gmail.com
    </a>
    <a href="mailto:noam.honig@gmail.com" target="_blank">
      <button mat-icon-button>
        <mat-icon>
          <img src="assets/github-mark.svg" style="height: 24px" />
        </mat-icon>
      </button>

      קוד המקור בגיטהאב
    </a>
  </div>

  <div style="display: flex; gap: 10px">
    <img src="assets/hagai.png" style="width: 100px" />
    <div>
      האפליקציה מוקדשת לזכרו של אחייני האהוב חגי איינמר שנפטר ב 15 לינואר 2020.
      <br />
      <a href="https://noam-honig.github.io/hagai" target="_blank">
        לחצו כאן לפרטים נוספים על חגי</a
      >
    </div>
  </div>
  <div>
    <H3 style="margin: 0">תודות</H3>
    <ul style="margin: 0">
      <li>
        <a href="https://www.linkedin.com/in/menachem-ainemer-1a212b32/"
          >למנחם איינמר</a
        >
        המסייע לכל הארגונים המשתמשים באפלקציות חגי למיניהן
      </li>
      <li>
        <a href="https://www.linkedin.com/in/aviad-turm/" target="_blank"
          >לאביעד טורם</a
        >
        מארגון "ידידים בדרכים" שמיהר לאמץ את הפתרון וניהל בחוכמה רבה את סדרי
        העדיפויות והגדרת הדרישות מהיום הראשון של האפליקציה
      </li>
      <li>
        לחברת <a href="https://points.co.il/" target="_blank">פוינטס</a> על סיוע
        בהגדרת האזורים המחוזות הגאוגרפים
      </li>
    </ul>
  </div>
  <a routerLink="" *ngIf="!signedIn()">כניסה לאפליקציה</a>
</div>
