<ng-container *ngIf="!result">
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 16px;
    "
  >
    <div style="align-self: center">
      <img [src]="getLogo()" height="150px" />
    </div>
    <!-- <strong style="align-self: center">{{ title }}</strong> -->
    <h2>בקשת טרמפ למחאה</h2>
    <div
      *ngIf="getSite().showComfortIntakeMessage"
      style="
        text-align: center;
        border: 1px solid;
        padding: 8px;
        font-weight: 500;
      "
    >
      מומלץ לנוסע לקיים שיחת וידאו עם הנהג לפני האיסוף.<br />האחריות הנה
      על הנוסע והנהג בלבד.
    </div>
  </div>
  <data-area [settings]="area"></data-area>
  <strong style="color: red" *ngIf="getAddressInstructions()">{{
    getAddressInstructions()
  }}</strong>
  <data-area [settings]="area2"></data-area>

  <button mat-raised-button color="primary" (click)="send()">שלח בקשה</button>
</ng-container>
<div *ngIf="result" style="padding: 16px">
  <h2>{{ result }}</h2>
  <button
    mat-raised-button
    color="primary"
    (click)="createAndCopyWhatsappLink()"
    *ngIf="allowShare()"
  >
    לחצו כאן להעתקת הודעה עם כל הפרטים לווטסאפ
  </button>
  <app-event-info
    [e]="r"
    [forExternalViewer]="true"
    context="צפייה אחרי הוספה"
  ></app-event-info>
  <div style="display: flex; justify-content: center">
    <a routerLink="אודות">אודות אפליקצית חגי שינועים</a>
  </div>
</div>
