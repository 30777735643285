<div class="column-container">
  <div *ngFor="let col of theColumns()">
    <ng-container *ngFor="let line of col">
      <div class="one-line">
        <ng-container *ngFor="let map of line">
          <data-control
            [settings]="settings.fields!"
            [map]="map"
            [record]="settings.fields!.currentRow()"
            *ngIf="settings.fields!.__visible(map,settings.fields!.currentRow())"
            [className]="_getRowColumnClass(map)"
            [style.maxWidth]="getColWidth(map)"
          >
          </data-control>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
