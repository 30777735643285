import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-in-middle',
  templateUrl: './card-in-middle.component.html',
  styleUrls: ['./card-in-middle.component.scss']
})
export class CardInMiddleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
